import queryString from 'querystring'

export const getOrigin = () => {
    if (typeof window !== 'undefined') {
        return window.location.origin
    }
    return ''
}

export const getCurrentDateSlashFormat = () => {
    const date = new Date()
    return date.toLocaleDateString()
}

export const getDTRStringFromLocation = (location={}) => {
    const queryParsedObj = queryString.parse(location.search.substr(1) || '')
    const dtrParam = queryParsedObj['dtr']
    if (!dtrParam) return
    return dtrParam.replace(/_/g, ' ')
}
