import React from 'react'
import { themeStyles, colors, presets } from '../utils/theme'
import visaPartner from '../img/VisaPartner.png'
import nvidiaPartner from '../img/nvidiaPartner.png'
import newsweekPartner from '../img/NewsweekPartner.png'
import CascadeLink from './CascadeLink'

export const newsweekNews = {
  'title': 'Newsweek Partnership',
  'description': 'LoanSnap ranked by Newsweek as America\'s Best Online Lender of 2024 with Top Rates Available',
  'link': 'https://www.newsweek.com/rankings/americas-best-online-lenders-2024/bmrl',
  'imageSource': newsweekPartner,
  'imageHeight': 30
}

export const visaNews = {
  'title': 'Visa Partnership',
  'description': 'LoanSnap Breaks New Ground as the First Mortgage Company to Enter Visa\'s Fintech Fast Track Program',
  'link': 'https://www.prnewswire.com/news-releases/loansnap-breaks-new-ground-as-the-first-mortgage-company-to-enter-visas-fintech-fast-track-program-302055404.html?tc=eml_cleartime',
  'imageSource': visaPartner,
  'imageHeight': 40
}

export const nvidiaNews = {
  'title': 'NVIDIA Partnership',
  'description': 'LoanSnap joins NVIDIA Inception Program',
  'link': 'https://www.prnewswire.com/news-releases/loansnap-joins-nvidia-inception-to-revolutionize-financial-health-with-ai-302125895.html?tc=eml_cleartime',
  'imageSource': nvidiaPartner,
  'imageHeight': 75
}

const styles = {
  section: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.lightGrey,
    marginTop: '1rem',
    width: '100vw',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'left',
    [presets.Desktop]: {
      ...themeStyles.textPadding,
      flexDirection: 'row',
    },
  },
  container: {
    margin: '1rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'left',
    maxWidth: '19rem',
    [presets.Tablet]: {
      maxWidth: '100%',
    },
  },
  logo: {
    width: 'auto',
    height: '4rem',
    objectFit: 'contain',
    justifyContent: 'center',
    marginTop: '1rem',
    marginBottom: '0.5rem',
    [presets.SmallMobile]: {
      height: '4rem',
    },
    [presets.Tablet]: {
      height: '3rem',
    },
  },
  text: {
    fontWeight: '600',
    marginBottom: '10px',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'left',
    paddingLeft: '.3rem',
    paddingRight: '.3rem',
    maxWidth: '14rem',
    fontSize: '15px',
    color: colors.darkGreen,
    [presets.SmallMobile]: {
      maxWidth: '19rem',
      fontSize: '19px',
    },
    [presets.Mobile]: {
      padding: 0,
      textAlign: 'left',
      fontSize: '20px',
    }
  },
  link: {
    color: colors.green,
    textAlign: 'left',
    fontSize: '13px',
    [presets.SmallMobile]: {
      fontSize: '17px',
    },
    [presets.Tablet]: {
      textAlign: 'left',
    }
  },
}

const BreakingNewsItem = ({ news, linkTitle, ...props }) => {
  const { description, link, title, imageSource, imageHeight } = news
  const linkText = linkTitle || 'Read More'
  return (
    <div css={styles.container}>
      {imageSource &&
        <img alt={title} src={imageSource} css={styles.logo} height={imageHeight}
          style={{ height: `${imageHeight}px` }}
        />}
      <div css={styles.text}>{description}</div>
      <CascadeLink analyticsTargetName={title} href={link} css={styles.link}>{linkText}</CascadeLink>
    </div>
  )
}

const BreakingNews = ({ items }) => {
  return (
    <section css={styles.section}>
      {items.map((item, index) => (
        <BreakingNewsItem key={index} news={item} />
      ))}
    </section>
  )
}

export default BreakingNews