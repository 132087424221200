import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { getDTRStringFromLocation } from '../utils/helpers'
import videoMobile from '../img/loan_DAG_animation_mobile.mp4'
import videoDesktop from '../img/loan_DAG_animation_desktop.mp4'
import CascadeLink from '../components/CascadeLink'

import Layout from '../components/Layout'
import FeaturedIn from '../components/FeaturedIn'
import whyLoansnap from '../img/WhyLoansnap_phone_crop.svg'
import Button from '../components/Button'
import ReviewRoll from '../components/ReviewRoll'
import heroArrow from '../img/arrowtip_grey.svg'
import LoanOfficerLogo from '../img/icons/LoanOfficer.svg'
import diamondGreen from '../img/diamond_green.svg'
import diamondGrey from '../img/diamond_grey.svg'
import howItWorks from '../img/Howitworks_phone.svg'
import LSLogo from '../img/icons/LoanSnap_Brandmark_no-padding.svg'
import LSViewInActionCTA from '../img/LoanFlow_view_in_action_mobile_button.svg'

import { themeStyles, colors, presets, metrics } from '../utils/theme'
import BreakingNews, { newsweekNews, visaNews, nvidiaNews } from '../components/BreakingNews'


const styles = {
  displayNone: {
    display: 'none',
    [presets.Mobile]: { display: 'none' },
    [presets.Tablet]: { display: 'none' },
    [presets.Desktop]: { display: 'none' },
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: `${themeStyles.elementMargin.md} 0`,
    ...themeStyles.contentWidth,
    ...themeStyles.textPadding,
  },
  center: {
    textAlign: 'center',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  hero: {
    height: 'auto',
    position: 'relative',
    [presets.Tablet]: {
      height: '550px',
    }
  },
  heroBg: {
    zIndex: -2,
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
  },
  featureVideoCnt: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  featureVideo: {
    height: '100%',
    objectFit: 'contain',
  },
  featureVideoMobile: {
    display: 'block',
    [presets.Mobile]: {
      display: 'none',
    }
  },
  featureVideoDesktop: {
    display: 'none',
    [presets.Mobile]: {
      display: 'block',
    }
  },
  featureVideoHintLabel: {
    position: 'absolute',
    bottom: '8px',
    fontWeight: '600',
    fontSize: '13px',
    color: colors.grey,
    opacity: 0.9,
    display: 'none',
    [presets.Tablet]: {
      display: 'block',
    }
  },
  heroContent: {
    backgroundColor: 'rgba(255,255,255,0.65)',
    ...themeStyles.textPadding,
    height: '100%',
    display: 'flex',
    lineHeight: '1',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  numberCircle: {
    padding: '5px',
    border: `1px solid ${colors.green}`,
    borderRadius: '100%',
    width: '35px',
    height: '35px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
  },
  mainHeader: {
    marginTop: '15px',
    fontSize: '28px',
    textAlign: 'center',
    color: colors.grey,
    maxWidth: '600px',
    fontWeight: 600,
    [presets.Tablet]: {
      fontSize: '46px',
      marginTop: '16px',
      marginBottom: '24px',
    }
  },
  subheading: {
    color: colors.grey,
    textAlign: 'center',
    fontSize: '18px',
    [presets.Mobile]: {
      fontSize: '24px',
    }
  },
  featureVideoMobileCnt: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [presets.Tablet]: {
      display: 'none',
    }
  },
  featureVideoMobileCntActive: {
    display: 'flex',
    [presets.Tablet]: {
      display: 'flex',
    },
  },
  featureVideoMobileCTA: {
    display: 'block',
    paddingTop: '8px',
    paddingBottom: '20px',
  },
  featureVideoMobileCTAInactive: {
    display: 'none',
  },
  featureVideoMobileCTAImg: {
    margin: 0,
  },
  featureVideoMobile: {
    display: 'none',
    width: '100%',
    objectFit: 'contain',
    marginTop: '-1px',
  },
  featureVideoMobileActive: {
    display: 'block',
  },
  featureVideoMobileTopSeparator: {
    display: 'none',
    borderTop: `1px solid ${colors.anotherGrey}`,
    width: '280px',
    zIndex: 3,
    [presets.Tablet]: {
      width: 'calc(100% - 2 * 15px)',
      maxWidth: `calc(${metrics.contentWidth} - 2 * 15px)`,
      minWidth: '280px',
    },
  },
  featuredIn: {
    paddingTop: 0,
    [presets.Tablet]: {
      paddingTop: 0,
      paddingLeft: '15px',
      paddingRight: '15px',
    }
  },
  numTitle: {
    marginLeft: '10px',
    color: colors.green,
    fontWeight: 'normal',
    marginBottom: '5px',
  },
  description: {
    marginTop: 0,
    marginLeft: '10px',
    marginBottom: '45px',
    maxWidth: '450px',
    fontWeight: 300,
  },
  rowCol: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [presets.Tablet]: {
      flexDirection: 'row',
      justifyContent: 'center',
    },
  },
  smartLoanRow: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: colors.white,
    zIndex: -1,
    position: 'relative',
    flexDirection: 'column',
    height: 'inherit',
    padding: '20px',
    [presets.Tablet]: {
      backgroundColor: colors.green,
      padding: '0px',
      flexDirection: 'row',
      height: '400px',
    }
  },
  nonBackgroundArrowContent: {
    backgroundColor: colors.grey,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-start',
    ...themeStyles.contentWidth,
    display: 'flex',
    flex: 1,
    flexDirection: 'column-reverse',
    [presets.Tablet]: {
      backgroundColor: 'transparent',
      width: 'inherit',
      display: 'flex',
      flexDirection: 'row',
    }
  },
  darkSection: {
    backgroundColor: colors.grey,
    height: '400px',
    display: 'flex',
    width: '38%',
  },
  arrowBackground: {
    width: '100%',
    height: '400px',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: colors.green,
    flexDirection: 'row',
    zIndex: -1,
    display: 'none',
    [presets.Tablet]: {
      display: 'flex'
    }
  },
  otherLendersContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignSelf: 'stretch',
    [presets.Desktop]: {
      flexDirection: 'row',
    },
  },
  otherLendersInner: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'stretch',
  },
  diamondImage: {
    width: '100%',
    maxWidth: '350px',
  },
  lenderDetail: {
    display: 'flex',
    alignSelf: 'stretch',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mockPhone: {
    width: '200px',
    display: 'none',
    [presets.Tablet]: {
      display: 'block',
    },
  },
  mockPhoneHolder: {
    marginLeft: '0px',
    marginTop: '30px',
    display: 'none',
    [presets.Tablet]: {
      display: 'block',
      marginTop: '0px',
      marginLeft: '100px',
    }
  },
  diamondSubtext: {
    background: colors.grey,
    color: colors.darkGreen,
    ...themeStyles.boxShadow,
    maxWidth: '270px',
    padding: '15px 25px',
    borderRadius: '10px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '40px',
    [presets.Tablet]: {
      marginBottom: 0,
    },
  },
  whyLoansnapPhone: {
    height: '200px',
    marginBottom: 0,
    [presets.Tablet]: {
      verticalAlign: 'bottom',
      marginTop: '50px',
      height: '350px',
    }
  },
  whyLoansnapPhoneHolder: {
    backgroundColor: colors.green,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [presets.Tablet]: {
      backgroundColor: 'inherit',
      width: '400px',
      height: '400px',
      marginLeft: '50px',
    }
  },
  li: {
    ':before': {
      content: '✓  ',
      color: colors.green,
      marginLeft: '-17px',
    }
  },
  diamondDivider: {
    width: '80px',
    height: '2px',
    backgroundColor: '#BCD8CC',
    marginBottom: '20px',
    marginTop: '20px',
  },
  whyLoanSnapText: {
    maxWidth: '300px',
    padding: '30px',
    [presets.Tablet]: {
      maxWidth: '350px',
      padding: '0',
      paddingLeft: '20px',
    },
  },
  brokerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '15rem',
    border: '1px solid',
    borderColor: colors.grey,
    borderRadius: '5px',
    position: 'unset',
    margin: '1rem auto',
    [presets.Hd]: {
      padding: '0.5rem 0',
      border: '2px solid',
      borderColor: colors.grey,
      textAlign: 'center',
      display: 'block',
      position: 'absolute',
      left: '75%',
      top: '12%',
      zIndex: 1,
    }
  },
  brokerSubcontainer: {
    margin: '1rem 0.5rem',
    [presets.Hd]: {
      margin: '0 0.5rem'
    }
  },
  brokerTitle: {
    color: colors.grey,
    fontSize: '0.8rem',
    textAlign: 'left',
    fontWeight: 600,
    [presets.Hd]: {
      textAlign: 'center',
      fontSize: '1rem',
    }
  },
  brokerLink: {
    color: colors.green,
    textDecoration: 'none',
    margin: 'auto'
  },
  brokerText: {
    fontSize: '1rem',
    textAlign: 'left',
    fontWeight: 600,
    fontSize: '0.6rem',
    [presets.Hd]: {
      textAlign: 'center',
      fontSize: '0.8rem',
    }
  },
  brokerSpacer: {
    width: '100%',
    margin: '0.5rem 0',
  },
  brokerImage: {
    margin: 'auto 0.8rem',
    width: '2.5rem',
    [presets.Hd]: {
      margin: '0.2rem'
    }
  },
}


export const IndexPageTemplate = ({
  image,
  title,
  mainCTALink,
  mainCTAText,
  subheading1,
  subheading2,
  heading1,
  description1,
  bulletHeading1,
  bullets1=[],
  heading2,
  diagramAHeader,
  diagramASubtext,
  diagramAHighlight,
  diagramBHeader,
  diagramBSubtext,
  diagramBHighlight,
  explainer,
  downloadCTAText,
  appAppleLink,
  appGoogleLink,
  brokerTitle,
  brokerText,
  brokerLink,
}) => {
  const mobileVideoCTARef = useRef()
  const mobileVideoRef = useRef()
  const [mobileShowVideo, setMobileShowVideo] = useState(false)
  const handleClickMobileShowVideo = (ev) => {
    ev.preventDefault()
    setMobileShowVideo(true)
    mobileVideoRef.current.currentTime = 0
    mobileVideoRef.current.play()
    window.scrollTo(0, mobileVideoCTARef.current.offsetTop - 90)
  }
  return (
  <div css={styles.mainContainer}>
    <div css={styles.hero}>
      <div css={styles.heroBg}>
        <div css={styles.featureVideoCnt}>
          <video
            css={[styles.featureVideo, styles.featureVideoDesktop, mobileShowVideo && styles.displayNone]}
            src={videoDesktop}
            autoPlay
            loop
            muted
          />
        </div>
      </div>
      <div css={styles.heroContent}>
        <h1 css={styles.mainHeader}>
          {title}
        </h1>
        <p css={styles.subheading}>
          {subheading1}
        </p>
        <Button
          size='lg'
          href={mainCTALink}
          text={mainCTAText}
        />
        <Button
          size='lg'
          href="https://secure.goloansnap.com/signin"
          text="Sign in"
          secondary
          style={{
            display: 'flex',
            backgroundColor: 'white',
            marginTop: '20px',
            [presets.Desktop]: {
              display: 'none',
            }
          }}
        />
        <div css={styles.featureVideoHintLabel}>
          * LoanFlow processing an actual loan
        </div>
      </div>
    </div>
    <div css={[styles.featureVideoMobileCnt, mobileShowVideo && styles.featureVideoMobileCntActive]}>
      <a
        href='#'
        ref={mobileVideoCTARef}
        css={[styles.featureVideoMobileCTA]}
        onClick={handleClickMobileShowVideo}
      >
        <img css={styles.featureVideoMobileCTAImg} src={LSViewInActionCTA} height="76" />
      </a>
      <div css={[styles.featureVideoMobileTopSeparator, mobileShowVideo && styles.featureVideoMobileActive]}/>
      <video
        data-qa='video-mobile'
        ref={mobileVideoRef}
        css={[styles.featureVideoMobile, mobileShowVideo && styles.featureVideoMobileActive]}
        src={videoMobile}
        loop
        muted
      />
    </div>
    <BreakingNews items={[newsweekNews, visaNews, nvidiaNews]}/>
    <div css={styles.brokerContainer}>
      <img css={styles.brokerImage} src={LoanOfficerLogo} alt='Broker Icon'></img>
      <div css={styles.brokerSubcontainer}>
        <div css={[styles.center, styles.brokerTitle]}>{brokerTitle}</div>
        <hr css={styles.brokerSpacer}></hr>
        <CascadeLink href={brokerLink} style={styles.brokerLink}>
          <div css={styles.brokerText}>{brokerText}</div>
        </CascadeLink>
      </div>
    </div>
    <FeaturedIn containerCss={styles.featuredIn} />
    <ReviewRoll />
    <div css={styles.contentContainer}>
      <h2 css={themeStyles.sectionHeader}>
        {heading1}
      </h2>
    </div>
    <div css={styles.smartLoanRow}>
      <div css={styles.nonBackgroundArrowContent}>
        <div css={styles.whyLoanSnapText}>
          <p css={{ color: colors.white, }}>
            {description1}
          </p>
          <p css={{ color: colors.white, margin: 0, fontWeight: 'normal'}}>
            {bulletHeading1}
          </p>
          <ul css={{ marginTop: '5px', color: colors.white, listStyle: 'none', paddingLeft: 0 }}>
            {bullets1.map(b => (<li css={styles.li} key={b.text}>{b.text}</li>))}
          </ul>
        </div>
        <div css={{ width: '170px', [presets.Desktop]: {width: '200px'}}}/>
        <div css={styles.whyLoansnapPhoneHolder}>
          <img src={whyLoansnap} css={styles.whyLoansnapPhone} loading="lazy" alt="Mobile mock up"/>
        </div>
      </div>
      <div css={styles.arrowBackground}>
        <div css={styles.darkSection} />
        <img src={heroArrow} css={{ height: '400px', marginBottom: 0}} alt="Arrow"/>
      </div>
    </div>
    <div css={styles.contentContainer}>
      <h2 css={themeStyles.sectionHeader}>
        {heading2}
      </h2>
      <div css={styles.otherLendersContainer}>
        <div css={styles.otherLendersInner}>
          <div css={styles.lenderDetail}>
            <h4 css={{color: colors.grey, margin: 0}}>
              {diagramAHeader}
            </h4>
            <div>
              {diagramASubtext}
            </div>
            <div css={styles.diamondDivider}/>
            <img src={diamondGrey} css={styles.diamondImage} loading="lazy" alt="Sub optimal loan illustration"/>
            <div css={{...styles.diamondSubtext, backgroundColor: colors.lightGrey}}>
              {diagramAHighlight}
            </div>
          </div>
        </div>
        <div css={styles.otherLendersInner}>
          <div css={styles.lenderDetail}>
            <h4 css={{color: colors.green, margin: 0}}>
              {diagramBHeader}
            </h4>
            <div>
              {diagramBSubtext}
            </div>
            <div css={{...styles.diamondDivider, backgroundColor: colors.green }} />
            <img src={diamondGreen} css={styles.diamondImage} loading="lazy" alt="Smart loan illustration"/>
            <div css={{...styles.diamondSubtext, backgroundColor: colors.tan}}>
              <img src={LSLogo} css={{width: '40px', margin: 0}} loading="lazy" alt="LoanSnap logo"/>
              <div>
                {diagramBHighlight}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div css={styles.contentContainer}>
      <div>
        <h2 css={[styles.center, themeStyles.sectionHeader]}>
          {explainer.title}
        </h2>
        <div css={styles.rowCol}>
          <div>
            {(explainer.items || []).map((item, index) => (
              <div key={index}>
                <div css={styles.row}>
                  <div css={styles.numberCircle} >{index + 1}</div>
                  <div css={{ display: 'flex', flexDirection: 'column', flexShrink: 1}}>
                    <h3 css={styles.numTitle} >{item.title}</h3>
                    <div css={styles.description}>
                      {item.description}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <Button
                href="https://secure.goloansnap.com"
                text="Get Started"
              />
            </div>
          </div>
          <div css={styles.mockPhoneHolder}>
            <img src={howItWorks} css={styles.mockPhone} loading="lazy" alt="Mobile mock up"/>
          </div>
        </div>
      </div>
    </div>
    <div css={{...themeStyles.contentWidth, ...themeStyles.textPadding, textAlign: 'right', fontSize: '12px'}}>
      *Examples only. Your options may vary.
    </div>
  </div>
)}

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  subheading1: PropTypes.string,
  subheading2: PropTypes.string,
  mainCTALink: PropTypes.string,
  mainCTAText: PropTypes.string,
  heading1: PropTypes.string,
  description1: PropTypes.string,
  bulletHeading1: PropTypes.string,
  bullets1: PropTypes.array,
  heading2: PropTypes.string,
  diagramAHeader: PropTypes.string,
  diagramASubtext: PropTypes.string,
  diagramAHighlight: PropTypes.string,
  diagramBHeader: PropTypes.string,
  diagramBSubtext: PropTypes.string,
  diagramBHighlight: PropTypes.string,
  explainer: PropTypes.object,
  downloadCTAText: PropTypes.string,
  appAppleLink: PropTypes.string,
  appGoogleLink: PropTypes.string,
  brokerTitle: PropTypes.string,
  brokerText: PropTypes.string,
  brokerLink: PropTypes.string,
}

const IndexPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark
  let textToDisplay = ''

  if (typeof window != 'undefined') {
    textToDisplay = getDTRStringFromLocation(location) || frontmatter.title
  }

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={textToDisplay}
        mainCTALink={frontmatter.mainCTALink}
        mainCTAText={frontmatter.mainCTAText}
        subheading1={frontmatter.subheading1}
        subheading2={frontmatter.subheading2}
        heading1={frontmatter.heading1}
        description1={frontmatter.description1}
        bulletHeading1={frontmatter.bulletHeading1}
        bullets1={frontmatter.bullets1}
        heading2={frontmatter.heading2}
        diagramAHeader={frontmatter.diagramAHeader}
        diagramASubtext={frontmatter.diagramASubtext}
        diagramAHighlight={frontmatter.diagramAHighlight}
        diagramBHeader={frontmatter.diagramBHeader}
        diagramBSubtext={frontmatter.diagramBSubtext}
        diagramBHighlight={frontmatter.diagramBHighlight}
        explainer={frontmatter.explainer}
        downloadCTAText={frontmatter.downloadCTAText}
        appAppleLink={frontmatter.appAppleLink}
        appGoogleLink={frontmatter.appGoogleLink}
        brokerTitle={frontmatter.brokerTitle}
        brokerText={frontmatter.brokerText}
        brokerLink={frontmatter.brokerLink}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        subheading1
        subheading2
        mainCTALink
        mainCTAText
        heading1
        description1
        bulletHeading1
        bullets1 {
          text
        }
        heading2
        diagramAHeader
        diagramASubtext
        diagramAHighlight
        diagramBHeader
        diagramBSubtext
        diagramBHighlight
        brokerTitle
        brokerText
        brokerLink
        explainer {
          title
          image {
            childImageSharp {
              gatsbyImageData(width: 500, quality: 100, layout: FULL_WIDTH)
            }
          }
          items {
            title
            description
          }
        }
        downloadCTAText
        appAppleLink
        appGoogleLink
      }
    }
  }
`
